import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { EmptyPoolExclusionCriteriaProvider } from "presentation/store/EmptyPoolExclusionCriteria/EmptyPoolExclusionCriteriaProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import { EmptyPoolExclusionCriteriaMaintenance } from "presentation/view/section/EmptyPoolExclusionCriteria/EmptyPoolExclusionCriteriaMaintenance";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const EmptyPoolExclusionCriteriaContMain = () => {
    return <ANAInfoWrapper permission={Permission.EMPTY_POOL_EXCL_CRITERIA_MAINTENANCE}>
        <MessageBarWrapper>
            <EmptyPoolExclusionCriteriaProvider>
                <GridStyles/>
                <EmptyPoolExclusionCriteriaMaintenance/>
            </EmptyPoolExclusionCriteriaProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default EmptyPoolExclusionCriteriaContMain;