import { useMemo } from "react";
import { useEmptyExclCriteriaTracked } from "presentation/store/EmptyPoolExclusionCriteria/EmptyPoolExclusionCriteriaProvider";
import { EmptyPoolExclusionCriteriaVM } from "presentation/viewModel/EmptyPoolExclusionCriteria/EmptyPoolExclusionCriteriaVM";
import { EmptyPoolExclCriteriaRepoImpl } from "domain/repository/EmptyPoolExclCriteria/EmptyPoolExclCriteriaRepoImpl";
import { OpsLineRepoImpl } from "domain/repository/OpsLine/OpsLineRepoImpl";
import { CompanyRepoImpl } from "domain/repository/Company/CompanyRepoImpl";
import { ParameterDetailRepoImpl } from "domain/repository/parameter/ParameterDetailRepoImpl";
import { TerminalRepoImpl } from "domain/repository/Terminal/TerminalRepoImpl";

export const useEmptyPoolExclusionCriteriaVM = () => {
    const [, setCriteriaState] = useEmptyExclCriteriaTracked();
    const emptyPoolExclVM = useMemo(() =>
        EmptyPoolExclusionCriteriaVM({
            dispatch: [setCriteriaState],
            emptyPoolExclRepo: EmptyPoolExclCriteriaRepoImpl(),
            opsLineRepo: OpsLineRepoImpl(),
            companyRepo: CompanyRepoImpl(),
            parameterDetailRepo: ParameterDetailRepoImpl(),
            terminalRepo: TerminalRepoImpl(),
        }), [setCriteriaState])

    return emptyPoolExclVM
}

