import { EMPTY_POOL_EXCL_MODEL } from "presentation/model/EmptyPoolExclusionCriteria/EmptyPoolExclusionCriteriaModel";
import { useState } from "react";
import { createContainer } from "react-tracked";

const {
    Provider: EmptyPoolExclusionCriteriaProvider,
    useTracked: useEmptyExclCriteriaTracked
} = createContainer(() => useState(EMPTY_POOL_EXCL_MODEL), {concurrentMode: true});
export { EmptyPoolExclusionCriteriaProvider, useEmptyExclCriteriaTracked };

