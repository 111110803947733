export interface EmptyPoolExclCriteriaEntity {
    id: number | null,
    opsLine: string | null,
    chargeOnCompany: string | null,  
    cntrStatus: string | null,
    block: string | null,
    stack: string | null,
    lane: string | null,
    tier: string | null,
    locationType: string | null,
    operatingTml: string | null,
    cntrType: string | null,
    exclusionType: string | null,
    activeInd: "Y" | "N",
    effectiveDate: Date | null,
    expiryDate: Date | null,

    [key: string]: string | boolean | number | null | undefined | Object
}

export const EMPTY_POOL_EXCL_CRITERIA_ENTITY : EmptyPoolExclCriteriaEntity = {
    id: null,
    opsLine: null,
    chargeOnCompany: null,  
    cntrStatus: null,
    block: null,
    stack: null,
    lane: null,
    tier: null,
    locationType: null,
    operatingTml: null,
    cntrType: null,
    exclusionType: 'EmptyPoolExclusionEntity',
    activeInd: "Y",
    effectiveDate: null,
    expiryDate: null,
}