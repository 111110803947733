
import { axiosPostData, axiosPutData } from "../axios/AxiosBasicImpl";
import { EmptyPoolExclCriteriaRepository } from "./EmptyPoolExclCriteriaRepo";
import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { EmptyPoolExclCriteriaEntity } from "domain/entity/EmptyPoolExclusion/EmptyPoolExclCriteriaEntity";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";

export const EmptyPoolExclCriteriaRepoImpl = (): EmptyPoolExclCriteriaRepository => {

    const url = '/v1/emptyPoolExclusionSearch';
    const updateUrl = '/v1/emptyPoolExclusion'

    const getEmptyPoolExclusion = async(): Promise<EmptyPoolExclCriteriaEntity[]> => {
        return axiosPostData(referenceAxiosInstance, `${url}`, []).then(res => {
            //return res.data;
            return res.data;
        }).catch(err => {
            return [];
        });
    }

    const createNewCriteria = async(newData: EmptyPoolExclCriteriaEntity) : Promise<ResponseEntity> => {
        return await axiosPostData(referenceAxiosInstance, `${updateUrl}`, newData)
    }

    const updateCriteria = async(updatedData: EmptyPoolExclCriteriaEntity): Promise<ResponseEntity> => {
        return await axiosPutData(referenceAxiosInstance, `${updateUrl}`, updatedData)
    }

    return {
        getEmptyPoolExclusion: getEmptyPoolExclusion,
        createNewCriteria: createNewCriteria,
        updateCriteria: updateCriteria,
    }
}