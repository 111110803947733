export const EmptyPoolExclusionConstant = {
    Charge:{
        CHARGE: "Charge",
    },
    Title: {
        EMPTY_POOL_EXCLUSION_MAIN: "Empty Pool Exclusion Criteria",
    },

    Table: {
        LABEL_NAME: "Empty Pool Exclusion Criteria List",
        OPS_LINE: "Ops Line",
        CHARGE_ON_COMPANY: "Charge On Company",
        EFFECTIVE_DATE: "Effective Date",
        OPERATING_TML: "Operating Tml.",
        EXPIRY_DATE: "Expiry Date",
        CONTAINER_TYPE: "Container Type",
        CONTAINER_STATUS: "Container Status",
        BLOCK: "Block",
        STACK: "Stack",
        LANE: "Lane",
        TIER: "Tier",
        LOCATION_TYPE: "Location Type",
        ACTIVE_IND: "Active Ind.",
    },
}