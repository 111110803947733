import * as yup from 'yup';

export const commonEmptyPoolExclusionValidation: { [x: string]: any; } = {
  effectiveDate: yup.string().required("Missing"),
  activeInd: yup.string().required("Missing"),
};

export const emptyPoolExclusionValidation: { [x: string]: any; } = {
  ...commonEmptyPoolExclusionValidation,
};

export const emptyPoolExclusionValidationSchema = yup.object().shape(emptyPoolExclusionValidation);
