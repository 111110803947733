import { SelectionChangedEvent } from "ag-grid-community";
import { EmptyPoolExclCriteriaEntity } from "domain/entity/EmptyPoolExclusion/EmptyPoolExclCriteriaEntity";
import { INITIAL_EMPTY_POOL_EXCL_COMP_COL_DEF, transferRowData } from "presentation/constant/EmptyPoolExclusion/EmptyPoolExclComponentColumnDefinition";
import { useEmptyPoolExclusionCriteriaVM } from "presentation/hook/EmptyPoolExclusionCriteria/useEmptyPoolExclusionCriteriaVM";
import { useEmptyExclCriteriaTracked } from "presentation/store/EmptyPoolExclusionCriteria/EmptyPoolExclusionCriteriaProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";

const EmptyPoolExclusionCriteriaComponentTablePanel: React.FC = () => {
    const [criteriaState] = useEmptyExclCriteriaTracked();
    const criteriaVM = useEmptyPoolExclusionCriteriaVM();
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);

    let gridRef: any = useRef();

    useEffect(() => {
        if (!onTableSelectionClicked) return;

        gridRef.current?.gridRef.current.api?.deselectAll();
    }, [onTableSelectionClicked])

    useEffect(() => {
        const columnDefs = (INITIAL_EMPTY_POOL_EXCL_COMP_COL_DEF.slice());

        //gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
        if (!onTableSelectionClicked) {
            gridRef.current?.gridRef.current.api?.deselectAll();
        }
    })

    const handleRowDoubleClick = useCallback((consGrpComp: EmptyPoolExclCriteriaEntity) => {
        criteriaVM.onRowDoubleClick(consGrpComp);
    }, [criteriaVM])


    const handleAddClick = useCallback(async () => {
        criteriaVM.onAdd();
    }, [criteriaVM])

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();
        criteriaVM.updateSelectedComponentRows(selectedRows);
    }, [criteriaVM])

    const memoCriteriaComponentTable = useMemo(() => {
        return <NbisTable
            id='CriteriaComponent-table'
            columns={INITIAL_EMPTY_POOL_EXCL_COMP_COL_DEF.slice()}
            data={transferRowData(criteriaState.criteriaEntityList ?? [])}
            showPaginator={false}
            editable={false}
            showAddIcon={!criteriaState.masterState.isSliderOpen}
            showDeleteButton={false}
            showReloadIcon={false}
            isScrollHighlighted={true}
            selectionMode={false}
            //rowSelection={"multiple"}
            isNewColumnSetting={true}
            onAddClick={handleAddClick}
            onSelectionChanged={handleSelectionChange}
            onRowDoubleClick={(e: any) => handleRowDoubleClick(e.data)}
            gridHeight="customHeight"
            customHeight="calc(100vh - 100px)"
            ref={gridRef} />;
    }, [criteriaState, handleAddClick, handleSelectionChange, handleRowDoubleClick])

    return <>
        {<TableWrapper>{memoCriteriaComponentTable}</TableWrapper>}
    </>;
}

export default memo(EmptyPoolExclusionCriteriaComponentTablePanel);